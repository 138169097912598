import React, { useEffect, useRef, useState } from "react";
import { tokenDataMappingT } from "../../TokenData";
import {
  Center,
  Flex,
  Text,
  Box,
  HStack,
  useDisclosure,
  useRadio,
  useRadioGroup,
  Button,
  useToast,
} from "@chakra-ui/react";
import zoomPlugin from "chartjs-plugin-zoom";
import "chartjs-adapter-moment";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
  Filler,
  TimeScale,
  LineController,
} from "chart.js";
import { format, getNum } from "../../Utils/number";
import { Chart } from "react-chartjs-2";
import { AdjustInput, Loader } from "../../Components";
import { ApolloClient, InMemoryCache, gql } from "@apollo/client/core";
import { LiquityStoreState } from "@yeti/lib-base";
import { useLiquitySelector } from "@yeti/lib-react";
import { Form } from "react-final-form";
import { validateDeposit } from "../../Utils/validation";
import ConfirmVaultModal from "./ConfirmVaultModal";
import { useParams } from "react-router-dom";

// export type TokenGraphProps = {
//   token: string;
// };

const selector = ({
  tokenBalances,
  vaultDepositInfo,
  prices,
}: LiquityStoreState) => ({
  tokenBalances,
  vaultDepositInfo,
  prices,
});

const TokenGraph: React.FC = () => {
  const { tokenBalances, vaultDepositInfo, prices } =
    useLiquitySelector(selector);

  const token = useParams().token!;

  const tokenInfo = tokenDataMappingT[token];

  const tokenAddress = tokenInfo.address;

  const userBalance = tokenBalances[tokenInfo.underlying];

  const userDeposit = vaultDepositInfo.vaultDeposits[tokenAddress];

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    LineController,
    Tooltip,
    Legend,
    TimeScale,
    zoomPlugin,
    Filler
  );

  const {
    isOpen: isFeeGraphOpen,
    onOpen: onFeeGraphOpen,
    onClose: onFeeGraphClose,
  } = useDisclosure();

  const {
    isOpen: isAPYGraphOpen,
    onOpen: onAPYGraphOpen,
    onClose: onAPYGraphClose,
  } = useDisclosure();

  const onFeeGraphClick = async () => {
    onFeeGraphOpen();
  };

  const onAPYGraphClick = async () => {
    onAPYGraphOpen();
  };

  const [portfolio, setPortfolio] = useState<any>({});

  const [loading, setLoading] = useState<boolean>(true);

  const [dataType, setDataType] = useState<string>("APY");

  const getData = async (dataType: string) => {
    let newData: any[] = [];
    const dates = [];
    if (dataType == "APY") {
      const url = `https://yeti-api-stage.herokuapp.com/v1/Collaterals/history/${token}`;
      const fetchData = async () => {
        try {
          const response = await fetch(url, {
            method: "GET",
            mode: "cors",
          });

          const data = await response.json();

          return data;
        } catch (error) {
          console.log("error", error);
        }
      };
      const apyList = await fetchData();

      // let dateStrings: string[] = []
      // let minValues = []
      // let maxValues = []
      const meanValues = [];

      for (let i = 0; i < apyList.length; i++) {
        const dataPoint = apyList[i];
        const date = new Date(dataPoint["_id"]);
        const dateString = date.toDateString();
        const value = dataPoint["average"];
        dates.push(date);
        // if (!dateStrings.includes(dateString)) {
        //   dateStrings.push(dateString)
        //   dates.push(date)
        //   minValues.push(value)
        //   maxValues.push(value)
        //   meanValues.push(value)
        // } else {
        //   let index = dateStrings.indexOf(dateString)
        //   let oldMin : number = minValues[index]
        //   let oldMax : number = maxValues[index]
        //   if (value < oldMin) {
        //     minValues[index] = value
        //   } else if (value > oldMax) {
        //     maxValues[index] = value
        //   }
        //   let oldMean : number = meanValues[index]
        //   meanValues[index] = (oldMean + value) / 2
        // }
        meanValues.push(value * 100);
      }
      newData = meanValues;
      if (token == "av3CRV") {
        dates.push(new Date());
        newData.push(0.0169);
      } else if (token == "sAVAX") {
        dates.push(new Date());
        newData.push(0.0576);
      }
    } else if (dataType == "BackFill") {
      const url = `https://yeti-api-stage.herokuapp.com/v1/Collaterals/history/${token}`;
      const fetchData = async () => {
        try {
          const response = await fetch(url, {
            method: "GET",
            mode: "cors",
          });

          const data = await response.json();

          return data;
        } catch (error) {
          console.log("error", error);
        }
      };
      const apyList = await fetchData();

      // let dateStrings: string[] = []
      // let minValues = []
      // let maxValues = []
      const meanValues = [];
      for (let i = 0; i < apyList.length; i++) {
        const dataPoint = apyList[i];
        const date = new Date(dataPoint["_id"]);
        const dateString = date.toDateString();
        const value = dataPoint["average"];
        dates.push(date);
        // if (!dateStrings.includes(dateString)) {
        //   dateStrings.push(dateString)
        //   dates.push(date)
        //   minValues.push(value)
        //   maxValues.push(value)
        //   meanValues.push(value)
        // } else {
        //   let index = dateStrings.indexOf(dateString)
        //   let oldMin : number = minValues[index]
        //   let oldMax : number = maxValues[index]
        //   if (value < oldMin) {
        //     minValues[index] = value
        //   } else if (value > oldMax) {
        //     maxValues[index] = value
        //   }
        //   let oldMean : number = meanValues[index]
        //   meanValues[index] = (oldMean + value) / 2
        // }
        meanValues.push(value * 100);
      }
      newData = meanValues;
    } else {
      const tvlQuery = `
          query {
            tvls(where: {collateral: "${tokenAddress.toLowerCase()}"}) {
              values
              amounts
              prices
              timestamps
            }
          }
          `;

      const client = new ApolloClient({
        uri: "https://api.thegraph.com/subgraphs/name/0xcano/yeti_troves",
        cache: new InMemoryCache(),
      });

      const result = await client
        .query({
          query: gql(tvlQuery),
        })
        .then((data: any) => data)
        .catch((err: any) => {
          console.log("Error fetching history data: ", err);
          return [];
        });

      if (result.length == 0) {
        console.log("no result");
        return [];
      }
      const amounts = result.data.tvls[0].amounts;
      const prices = result.data.tvls[0].prices;
      const timestamps = result.data.tvls[0].timestamps;
      const dateStrings: string[] = [];
      const allTVLs: number[][] = [];
      const allPrices: number[][] = [];
      for (let i = 0; i < amounts.length; i++) {
        let tvl = amounts[i] * prices[i];
        if (tvl < 0) {
          console.log("NEGATIVE TVL", tvl);
          tvl *= -1;
        }
        const price = prices[i];
        const timestamp = timestamps[i];
        const date = new Date(timestamp * 1000);
        const dateString = date.toDateString();
        if (!dateStrings.includes(dateString)) {
          dateStrings.push(dateString);
          dates.push(date);

          allTVLs.push([tvl / 10 ** 36]);
          allPrices.push([price / 10 ** 18]);
        } else {
          const index = dateStrings.indexOf(dateString);
          allTVLs[index].push(tvl / 10 ** 36);
          allPrices[index].push(price / 10 ** 18);
        }
      }
      const meanTVL: number[] = [];
      const meanPrice: number[] = [];
      allTVLs.map((values) => {
        const sum = values.reduce((a, b) => a + b, 0);
        meanTVL.push(sum / values.length);
      });
      allPrices.map((prices) => {
        const sum = prices.reduce((a, b) => a + b, 0);
        meanPrice.push(sum / prices.length);
      });

      if (dataType == "TVL") {
        newData = meanTVL;
      } else if (dataType == "Price") {
        newData = meanPrice;
      }
    }

    const apyData = {
      labels: dates,
      datasets: [
        // {
        //     label: 'min',
        //     data: minValues,
        //     color: 'red'
        // },
        // {
        //     label: 'max',
        //     data: maxValues,
        //     color: 'green'
        // },
        {
          label: dataType,
          data: newData,
          fill: "origin",
          borderColor: "rgb(75, 192, 192, 1)",
          backgroundColor: "rgb(75, 192, 192, 0.2)",
          tension: 0.4,
        },
        // {
        //   label: 'ICR',
        //   data: icrs,
        // }
      ],
    };
    // const port = {
    //   ...apyData,
    //   datasets: apyData.datasets.map((dataset: any) => ({
    //     ...dataset,
    //   })),
    // };

    setPortfolio(apyData);

    setLoading(false);
  };

  // console.log('modal data', data)

  useEffect(() => {
    getData(dataType);
  }, [dataType]);

  const options: any = {
    scales: {
      xAxes: {
        ticks: {
          color: "white",
        },
        type: "time",
        title: {
          display: true,
          color: "white",
          text: "Date",
        },
      },
      yAxes: {
        grid: {
          display: true,
          z: -1,
          color: "rgb(200, 251, 246, 0.2)",
        },
        ticks: {
          suggestedMin: 0,
          beginAtZero: true,
          color: "white",
          display: true,
        },
        title: {
          display: false,
          color: "white",
          text: dataType,
        },
      },
    },
    interaction: {
      intersect: false,
      mode: "index",
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: function (tooltipItem: any, data: any) {
            if (dataType == "APY" || dataType == "BackFill") {
              return Number(tooltipItem["raw"]).toFixed(2) + "%";
            } else {
              return "$" + getNum(format(Number(tooltipItem["raw"])), 2);
            }
          },
        },
      },
      legend: {
        display: false,
        labels: {
          color: "white",
        },
      },
      // zoom: {
      //   zoom: {
      //     wheel: {
      //       enabled: true,
      //     },
      //     pinch: {
      //       enabled: true,
      //     },
      //     mode: 'xy',
      //     overScaleMode: 'y'
      //   },
      //   pan: {
      //     enabled: true,
      //     mode: 'xy',
      //   }
      // }
    },
  };
  const {
    isOpen: isConfirmOpen,
    onOpen: onConfirmOpen,
    onClose: onConfirmClose,
  } = useDisclosure();

  const toast = useToast();

  const validate = (valueChange: number) => {
    validateDeposit(
      toast,
      "deposit",
      format(userBalance),
      format(userDeposit),
      valueChange,
      onConfirmOpen
    );
  };

  const chartRef = useRef<ChartJS>(null);

  function RadioCard(props: any) {
    const { getInputProps, getCheckboxProps } = useRadio(props);

    const input = getInputProps();
    const checkbox = getCheckboxProps();

    return (
      <Box as="label">
        <input {...input} />
        <Box
          {...checkbox}
          cursor="pointer"
          borderWidth="1px"
          borderRadius="md"
          boxShadow="md"
          color="white"
          _checked={{
            bg: "teal.600",
            color: "white",
            borderColor: "teal.600",
          }}
          _focus={{
            boxShadow: "outline",
          }}
          px={5}
          py={3}
        >
          {props.children}
        </Box>
      </Box>
    );
  }

  const graphOptions = ["APY", "TVL", "Price"];

  const handleRadiochange = (value: any) => {
    setDataType(value);
  };

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: "framework",
    defaultValue: "APY",
    onChange: handleRadiochange,
  });

  const group = getRootProps();

  console.log();

  const isConstantAPY = () => {
    return ["av3CRV", "sAVAX"].includes(token!) && dataType == "APY";
  };

  const getConstantAPY = () => {
    if (token == "av3CRV") {
      return 0.0169;
    } else if (token == "sAVAX") {
      return 0.0576;
    }
  };

  // const getFormattedValue = (value: string) => {
  //   if (/^[0-9.]*$/.test(value)) {
  //     if (max != undefined && value != "") {
  //       const decimalInput: Decimal = Decimal.from(value);
  //       const decimalMax: Decimal = Decimal.from(max);
  //       if (decimalInput.gte(decimalMax)) {
  //         return decimalMax.toString();
  //       }
  //     }

  //     return value;
  //   }
  //   return "";
  // };

  return (
    //TODO dynamic mode
    <>
      {" "}
      <Center>
        {" "}
        <Text textStyle="title4" mb={4} mt={4}>
          {token} History{" "}
        </Text>{" "}
      </Center>
      <HStack mb={3} {...group}>
        {graphOptions.map((value) => {
          const radio = getRadioProps({ value });
          return (
            <RadioCard key={value} {...radio}>
              {value}
            </RadioCard>
          );
        })}
      </HStack>
      <Center border="2px" borderColor="#323545" alignItems={"center"}>
        {
          loading ? (
            <Flex mt={3} flexDirection="column" alignItems="center" gap={4}>
              <Loader />
              <Text textStyle="title4"> Loading</Text>
            </Flex>
          ) : portfolio.labels.length == 0 ? (
            <>
              <Text mt={5} mb={5} textStyle="title4">
                Data unavailable
              </Text>
            </>
          ) : isConstantAPY() ? (
            <>
              <Text mt={5} mb={5} textStyle="title4">
                APY: {getConstantAPY()! * 100}%
              </Text>
            </>
          ) : (
            // <Flex flexDirection="column" alignItems={"center"} gap={4}>
            <Chart
              ref={chartRef}
              type="line"
              data={portfolio}
              color="white"
              options={options}
            />
          )

          // </Flex>
        }
      </Center>
      <Box flexDir="column" display="flex" w="full" gap="0px">
        <Box layerStyle="card" w="340px" p="24px">
          <Form
            onSubmit={() => undefined}
            render={({ values, form }) => (
              <>
                {isConfirmOpen && (
                  <ConfirmVaultModal
                    isOpen={isConfirmOpen}
                    onClose={onConfirmClose}
                    mode="deposit"
                    token={token}
                    amount={values[token + "vaultDeposit"]}
                    total={
                      Number(values[token + "vaultDeposit"]) +
                      Number(userDeposit.toString())
                    }
                    userBalance={userBalance}
                    userDeposit={userDeposit}
                  />
                )}
                <AdjustInput
                  mt={4}
                  mb={4}
                  max={format(userBalance)}
                  name={token + "vaultDeposit"}
                  token={token}
                  showToken
                  transactionId="tokenDeposit"
                  form={form}
                  walletBalance={format(userBalance)}
                />
                <Button
                  variant="newPrimary"
                  mb={4}
                  onClick={() => validate(values[token + "vaultDeposit"])}
                >
                  Deposit
                </Button>
              </>
            )}
          />
        </Box>
      </Box>
    </>
  );
};

export default TokenGraph;
