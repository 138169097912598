import React from "react";
import { Center, Text } from "@chakra-ui/react";
import { Loader } from "../../Components";
import Snowfall from "react-snowfall";

const Loading: React.FC = () => (
  <Center flexDirection="column" w="100vw" h="100vh">
    <Snowfall snowflakeCount={100} style={{ zIndex: 1 }} />
    <Loader />
    <Text textStyle="title3" mt={4}>
      Loading...
    </Text>
  </Center>
);

export default Loading;
