import React, { useEffect } from "react";
import { PageWrapper, Navbar, LiquidationEvent, Popup } from "./Components";
import {
  // Dashboard,
  Stake,
  Borrow,
  Pool,
  Loading,
  LiquidationCalculator,
  Stats,
  Vaults,
} from "./Screens";
import Redemption from "./Screens/Redemption";
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import { useLiquity } from "./Hooks/LiquityContext";
import { LiquityStoreProvider } from "@yeti/lib-react";
import { StabilityViewProvider } from "./PageComponents/Pool/context/StabilityViewProvider";
import { QueryClient, QueryClientProvider } from "react-query";
import { TransactionMonitor } from "./Components/Transaction";
import { Box, useDisclosure } from "@chakra-ui/react";
import StrategiesCalculator from "./PageComponents/StrategiesCalculator";
import Footer from "./Components/Footer";
import Profile from "./Screens/Profile";
import { useWeb3Context } from "./Hooks/useWeb3Context";
import ReactGa from "react-ga";
import { useLocation } from "react-router-dom";
import Announcement from "./Components/Announcement/Announcement";
import Terms from "./Screens/Terms";
import TokenGraph from "./PageComponents/TokenGraph";
// import { Banner } from "./Components/Banner/Banner";

interface WrapperProps {
  intialized: boolean;
  children: React.PropsWithChildren<any>;
}

export function Wrapper(props: WrapperProps) {
  const location = useLocation();

  React.useEffect(() => {
    if (props.intialized) {
      // console.log("pageview", location.pathname, location.search);
      ReactGa.pageview(location.pathname + location.search);
    }
  }, [props.intialized, location]);

  return props.children;
}

export function useAnalytics() {
  const [initialized, setInitalized] = React.useState(false);

  useEffect(() => {
    ReactGa.initialize("UA-239245708-2");

    setInitalized(true);
  }, []);

  return { initialized };
}

const YetiFrontend: React.FC = () => {
  const { liquity, account } = useLiquity();
  const { connected } = useWeb3Context();
  const queryClient = new QueryClient();

  let agreedToDisclaimer;
  useEffect(() => {
    if (
      localStorage.getItem(account + "agreedToYetiFinanceDisclaimerMainnet") ==
      undefined
    ) {
      agreedToDisclaimer = true;
    } else {
      agreedToDisclaimer = false;
    }
  }, [localStorage.getItem(account + "agreedToYetiFinanceDisclaimerMainnet")]);

  let borrowInfograph;
  if (
    localStorage.getItem(account + "agreedToYetiBorrowInfograph") == undefined
  ) {
    borrowInfograph = true;
  } else {
    borrowInfograph = false;
  }

  let farmInfograph;

  if (
    localStorage.getItem(account + "agreedToYetiFarmInfograph") == undefined
  ) {
    farmInfograph = true;
  } else {
    farmInfograph = false;
  }

  let veYETIInfograph;

  if (
    localStorage.getItem(account + "agreedToYetiveYETIInfograph") == undefined
  ) {
    veYETIInfograph = true;
  } else {
    veYETIInfograph = false;
  }
  const { isOpen: isAddCollateralTypeOpen, onClose: onAddCollateralTypeClose } =
    useDisclosure({
      defaultIsOpen: true,
    });

  const { isOpen: isBorrowOpen, onClose: onBorrowOpen } = useDisclosure({
    defaultIsOpen: true,
  });

  const { isOpen: isStakeOpen, onClose: onStakeOpen } = useDisclosure({
    defaultIsOpen: true,
  });

  const { isOpen: isFarmOpen, onClose: onFarmOpen } = useDisclosure({
    defaultIsOpen: true,
  });

  const { initialized } = useAnalytics();

  return (
    <LiquityStoreProvider loader={<Loading />} store={liquity.store}>
      <QueryClientProvider client={queryClient}>
        <Router>
          <StabilityViewProvider>
            <Announcement />
            <LiquidationEvent />
            {/* <Banner /> */}
            <>
              <Wrapper intialized={initialized}>
                <Navbar walletConnected={connected} dashboardVisible={true} />
                <PageWrapper>
                  {/* <Box
                  w="300px"
                  position="fixed"
                  left="0px"
                  top="0px"
                  bottom="0px"
                  bg="surface"
                >
                  sidebar
                </Box> */}
                  <Switch>
                    <Route path="/borrow">
                      {/* {borrowInfograph && (
                      <Popup
                        isOpen={isBorrowOpen}
                        onClose={onBorrowOpen}
                        header="Borrow"
                        infographSrc="/img/borrowdiagram.png"
                        mode="borrow"
                      />
                    )} */}
                      <Borrow />
                    </Route>
                    <Route path="/farm">
                      {/* {farmInfograph && (
                      <Popup
                        isOpen={isFarmOpen}
                        onClose={onFarmOpen}
                        header="Farm"
                        infographSrc="/img/farm2.png"
                        mode="farm"
                      />
                    )} */}
                      <Pool />
                    </Route>
                    <Route path="/veYETI">
                      {/* {veYETIInfograph && (
                      <Popup
                        isOpen={isStakeOpen}
                        onClose={onStakeOpen}
                        header="YETI Staking"
                        infographSrc="/img/stakediagram.png"
                        mode="veYETI"
                      />
                    )} */}
                      <Stake />
                    </Route>

                    <Route path="/terms">
                      {/* {veYETIInfograph && (
                      <Popup
                        isOpen={isStakeOpen}
                        onClose={onStakeOpen}
                        header="YETI Staking"
                        infographSrc="/img/stakediagram.png"
                        mode="veYETI"
                      />
                    )} */}
                      <Terms />
                    </Route>

                    <Route path="/vaults">
                      {localStorage.getItem(
                        account + "agreedToYetiFinanceDisclaimerMainnet"
                      ) == undefined && (
                        <Popup
                          isOpen={isAddCollateralTypeOpen}
                          onClose={onAddCollateralTypeClose}
                          header="Disclaimer: Risks of Using Protocol"
                        />
                      )}
                      <Vaults />
                    </Route>
                    <Route path="/vault/:token">
                      {localStorage.getItem(
                        account + "agreedToYetiFinanceDisclaimerMainnet"
                      ) == undefined && (
                        <Popup
                          isOpen={isAddCollateralTypeOpen}
                          onClose={onAddCollateralTypeClose}
                          header="Disclaimer: Risks of Using Protocol"
                        />
                      )}
                      <TokenGraph />
                    </Route>
                    <Route path="/profile">
                      {localStorage.getItem(
                        account + "agreedToYetiFinanceDisclaimerMainnet"
                      ) == undefined && (
                        <Popup
                          isOpen={isAddCollateralTypeOpen}
                          onClose={onAddCollateralTypeClose}
                          header="Disclaimer: Risks of Using Protocol"
                        />
                      )}
                      <Profile walletConnected={connected} />
                    </Route>

                    <Route path="/stats">
                      {localStorage.getItem(
                        account + "agreedToYetiFinanceDisclaimerMainnet"
                      ) == undefined && (
                        <Popup
                          isOpen={isAddCollateralTypeOpen}
                          onClose={onAddCollateralTypeClose}
                          header="Disclaimer: Risks of Using Protocol"
                        />
                      )}
                      <Stats />
                    </Route>
                    <Route path="/calculator">
                      {localStorage.getItem(
                        account + "agreedToYetiFinanceDisclaimerMainnet"
                      ) == undefined && (
                        <Popup
                          isOpen={isAddCollateralTypeOpen}
                          onClose={onAddCollateralTypeClose}
                          header="Disclaimer: Risks of Using Protocol"
                        />
                      )}
                      <LiquidationCalculator />
                    </Route>
                    <Route path="/strategies">
                      {localStorage.getItem(
                        account + "agreedToYetiFinanceDisclaimerMainnet"
                      ) == undefined && (
                        <Popup
                          isOpen={isAddCollateralTypeOpen}
                          onClose={onAddCollateralTypeClose}
                          header="Disclaimer: Risks of Using Protocol"
                        />
                      )}
                      <StrategiesCalculator />
                    </Route>
                    <Route path="/redemption">
                      {localStorage.getItem(
                        account + "agreedToYetiFinanceDisclaimerMainnet"
                      ) == undefined && (
                        <Popup
                          isOpen={isAddCollateralTypeOpen}
                          onClose={onAddCollateralTypeClose}
                          header="Disclaimer: Risks of Using Protocol"
                        />
                      )}
                      <Redemption />
                    </Route>
                    <Route path="/">
                      {localStorage.getItem(
                        account + "agreedToYetiFinanceDisclaimerMainnet"
                      ) == undefined && (
                        <Popup
                          isOpen={isAddCollateralTypeOpen}
                          onClose={onAddCollateralTypeClose}
                          header="Disclaimer: Risks of Using Protocol"
                        />
                      )}
                      <Borrow />
                    </Route>
                  </Switch>
                  <Box
                    display="flex"
                    h="full"
                    justifyContent="flex-end"
                    alignItems="flex-end"
                  >
                    <Footer />
                  </Box>
                </PageWrapper>
              </Wrapper>
            </>
          </StabilityViewProvider>
        </Router>
      </QueryClientProvider>
      <TransactionMonitor />
    </LiquityStoreProvider>
  );
};

export default YetiFrontend;
