import React from "react";
import { Box, Text } from "@chakra-ui/react";
import {
  VEStakeCard,
  VEStakeSummary,
  VEEmissions,
} from "../../PageComponents/VEStake";
import { LiquityStoreState } from "@yeti/lib-base";
import { useLiquitySelector } from "@yeti/lib-react";
import { format } from "../../Utils/number";

// import { calculateHealth, calculateHealthColor, calculateHealthStableTrove } from "../../PageComponents/Borrow/Trove";

export type StakeProps = {
  disconnected?: boolean;
};

const selector = ({ veYETIStaked }: LiquityStoreState) => ({
  veYETIStaked,
});

const Stake: React.FC<StakeProps> = ({ disconnected = false }) => {
  const { veYETIStaked } = useLiquitySelector(selector);
  const yetiEarned: number = format(veYETIStaked.yetiEarned);
  const totalStaked: number = format(veYETIStaked.totalUserYeti);
  return (
    <>
      <Box
        w="full"
        h="284px"
        bg="#141724"
        position="absolute"
        zIndex="-1"
        left="0"
        right="0"
        top="0"
      />

      <Box>
        <Text
          color="white"
          textStyle="display_md"
          borderBottom="1px"
          borderColor="#31354F"
          pb="8px"
        >
          Stake
        </Text>
        {/* <Text
          textStyle="text_md"
          color="text-secondary"
          textAlign={["center", "left"]}
          fontWeight="normal"
          pb="32px"
        >
          Stake YETI to earn veYETI. Apply veYETI to activate utility.
        </Text> */}
        <Box display="flex" justifyContent="center" gap="16px" mt="48px">
          <VEStakeCard disconnected={disconnected} />{" "}
          {(yetiEarned !== 0 || totalStaked !== 0) && (
            <>
              <Box display="flex" w="full" gap="16px" flexDir="column">
                <VEEmissions />
                <VEStakeSummary />
              </Box>
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

export default Stake;
