import React, { useState } from "react";
import {
  Text,
  Link,
  useClipboard,
  Box,
  useColorMode,
  Img,
  Button,
  //   Switch,
} from "@chakra-ui/react";
import {
  ExternalLinkIcon,
  UnlockIcon,
  //   CopyIcon,
  //   CheckIcon,
  //   StarIcon,
  // MoonIcon,
  // SunIcon,
} from "@chakra-ui/icons";
import { LiquityStoreState } from "@yeti/lib-base";
import { useLiquitySelector } from "@yeti/lib-react";
import { useWeb3React } from "@web3-react/core";
import Icon from "../../Components/Icon";
import { useLiquity } from "../../Hooks/LiquityContext";
import { formatWithDecimals } from "../../Utils/number";
import { walletConnectConnector } from "../../connectors/injectedConnector";
import { formatWalletAddress } from "../../Utils/string";
// import { CoinAmount } from "../../Components";
import { TroveHistory } from "../../PageComponents/Dashboard";
import makeBlockie from "ethereum-blockies-base64";
import { ConnectButton } from "../../Components/ConnectButton";

const selector = ({
  yusdBalance,
  yetiBalance,
  veYETIStaked,
}: LiquityStoreState) => ({
  yusdBalance,
  yetiBalance,
  veYETIStaked,
});

export type ProfileProps = {
  disconnected?: boolean;
  walletConnected: boolean;
};

const Profile: React.FC<ProfileProps> = ({ walletConnected }) => {
  const { deactivate, connector } = useWeb3React<unknown>();

  const {
    liquity: {
      connection: { addresses },
    },
  } = useLiquity();
  const { account } = useLiquity();
  const { yusdBalance, yetiBalance, veYETIStaked } =
    useLiquitySelector(selector);
  const [copyValue, setCopyValue] = useState(account);
  const { hasCopied, onCopy } = useClipboard(copyValue);

  const totalVeYeti: number = formatWithDecimals(veYETIStaked.veYETITotal, 36);

  const addToken = (params: any) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const func = window.ethereum.request;

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    func({ method: "wallet_watchAsset", params }) //@ts-ignore
      .then(() => setLog([...log, "Success, Token added!"]))
      .catch((error: Error) => setLog([...log, `Error: ${error.message}`]));
  };

  async function deactivateWallet() {
    try {
      if (
        (await walletConnectConnector.getProvider()) != undefined &&
        (await walletConnectConnector.getProvider()).connected == true
      ) {
        walletConnectConnector.close();
      } else {
        deactivate();
      }
    } catch (ex) {
      console.error(ex);
    }
  }

  const [log, setLog] = useState<string[]>([]);

  const addYusdToken = () => {
    addToken({
      type: "ERC20",
      options: {
        address: addresses["yusdToken"],
        symbol: "YUSD",
        decimals: 18,
        image: "https://i.ibb.co/DCL8fhg/yusd-Token.png",
      },
    });
  };

  const CircleIcon = (props: any) => (
    <Icon viewBox="0 0 200 200" {...props}>
      <path
        fill="currentColor"
        d="M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0"
      />
    </Icon>
  );

  const addYetiToken = () => {
    addToken({
      type: "ERC20",
      options: {
        address: addresses["yetiToken"],
        symbol: "YETI",
        decimals: 18,
        image: "https://i.ibb.co/fvT5sMy/YETI-LOGO.png",
      },
    });
  };

  const { colorMode, toggleColorMode } = useColorMode();

  const addy = formatWalletAddress(account, 12, 8, 100);

  return (
    <>
      <Box
        w="full"
        h="480px"
        bg="#141724"
        position="absolute"
        zIndex="-1"
        left="0"
        right="0"
        top="0"
      />

      <Text
        color="white"
        textStyle="display_md"
        borderBottom="1px"
        borderColor="#31354F"
        pb="8px"
      >
        Profile
      </Text>
      <Box display="flex" flexDir="column" alignItems="center">
        {/* <Box
          w="80px"
          h="80px"
          bg="surface"
          justifyContent="center"
          alignItems="center"
          display="flex"
          rounded="full"
        >
          <StarIcon boxSize="40px" color="text-secondary"></StarIcon>
        </Box> */}
        <Box
          display="flex"
          gap="8px"
          alignItems="center"
          mt="48px"
          flexDir="column"
        >
          {/* <CircleIcon color="#26B571" boxSize="8px" /> */}
          {walletConnected ? (
            <>
              <Img
                src={makeBlockie(account)}
                h="64px"
                w="64px"
                rounded="full"
              />
              <Box alignItems="center" display="flex" gap="16px">
                <Text
                  onClick={onCopy}
                  display="flex"
                  alignItems="center"
                  fontSize="15px"
                  fontWeight="600"
                  cursor="pointer"
                  color="white"
                  mt="6px"
                >
                  {/* {formatWalletAddress(account, 6, 4, 13)} */}
                  {/* {hasCopied ? (
                          <CheckIcon mr="6px" color="green.400" />
                        ) : (
                          <CopyIcon mr="6px" />
                        )} */}
                  {hasCopied ? "Copied" : addy}
                </Text>
                <Link
                  href={`https://snowtrace.io/address/${account}`}
                  isExternal
                  fontWeight="semibold"
                  fontSize={14}
                  color="text-secondary"
                >
                  <ExternalLinkIcon />
                </Link>
              </Box>
            </>
          ) : (
            <></>
          )}
          <Box mt="8px">
            {walletConnected ? (
              <Button
                variant="surface"
                color=""
                onClick={deactivate}
                leftIcon={<UnlockIcon color="red.400" />}
                bg="#434658"
              >
                <Text color="white">Disconnect Wallet</Text>
              </Button>
            ) : (
              <ConnectButton />
            )}
          </Box>
        </Box>

        {/* <Switch defaultChecked={true} onChange={toggleColorMode} id="email-alerts" /> */}
        {/* <Box
          display="flex"
          gap="12px"
          alignItems="center"
          bg="#272937"
          p="12px"
          my="24px"
          rounded="12px"
        >
          <Box
            display="flex"
            alignItems="center"
            bg="#434658"
            p="12px"
            rounded="6px"
            onClick={toggleColorMode}
            cursor="pointer"
          >
            <Text color="white">Switch Theme:</Text>
            <Box ml="8px">
              {colorMode === "light" ? (
                <MoonIcon color="white" />
              ) : (
                <SunIcon w="18px" h="18px" color="white" />
              )}
            </Box>
          </Box>
        </Box> */}
      </Box>
      {/* <Flex justifyContent="space-between" mt={8}>
        <Text textStyle="subheading" color="text-secondary">
          Asset
        </Text>
        <Text textStyle="subheading" color="text-secondary">
          Wallet Balance
        </Text>
      </Flex> */}
      {/* <Flex justifyContent="space-between" mt={5}>
        <ChakraTooltip
          label={"Add YUSD token to Wallet"}
          placement="top"
          bg="surface"
        >
          <Flex
            align="center"
            mr={10}
            onClick={addYusdToken}
            _hover={{ cursor: "pointer" }}
          >
            <Icon iconName={"YUSD"} h={6} w={6} />
            <Text textStyle="body2" ml={3}>
              {"YUSD"}
            </Text>
          </Flex>
        </ChakraTooltip>
        <CoinAmount token={"YUSD"} amount={format(yusdBalance)} />
      </Flex> */}
      {/* <Flex justifyContent="space-between" mt={4}>
        <ChakraTooltip
          label={"Add YETI token to Wallet"}
          placement="top"
          bg="surface"
        >
          <Flex
            align="center"
            mr={10}
            onClick={addYetiToken}
            _hover={{ cursor: "pointer" }}
          >
            <Icon iconName={"YETI"} h={6} w={6} />
            <Text textStyle="body2" ml={3}>
              {"YETI"}
            </Text>
          </Flex>
        </ChakraTooltip>
        <CoinAmount token={"YETI"} amount={format(yetiBalance)} />
      </Flex> */}
      {/* <Flex justifyContent="space-between" mt={4}>
        <Flex align="center" mr={10}>
          <Icon iconName={"YETI"} h={6} w={6} />
          <Text textStyle="body2" ml={3}>
            {"veYETI"}
          </Text>
        </Flex>
        <CoinAmount
          noCurrencyConvert={true}
          token={"veYETI"}
          amount={format(totalVeYeti)}
        />
      </Flex> */}

      <Box display="flex" mt="48px">
        <TroveHistory />
      </Box>
    </>
  );
};

export default Profile;
