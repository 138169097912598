import React, { useState } from "react";
import { Flex, Box, Text } from "@chakra-ui/react";
import { ConnectCard } from "../../Components";
import { AdjustTrove, CloseTroveAutosell } from "../../PageComponents/Borrow";
// import TroveHistory from "../../PageComponents/Dashboard/TroveHistory";
// import { getNum } from "../../Utils/number";

export type BorrowProps = {
  disconnected?: boolean;
  // currUSDValue: number;
};

// const selector = ({ trove }: LiquityStoreState) => ({
//   trove
// });

const Borrow: React.FC<BorrowProps> = ({
  disconnected = false,
  // currUSDValue,
}) => {
  const [borrowMode, setBorrowMode] = useState<
    "normal" | "lever" | "unlever" | "close"
  >("normal");

  const [active, setActive] = useState<"overview" | "history" | "liquidations">(
    "overview"
  );

  return (
    <>
      <Box
        w="full"
        h="638px"
        bg="#141724"
        position="absolute"
        zIndex="-1"
        left="0"
        right="0"
        top="0"
      />
      <Text
        color="white"
        textStyle="display_md"
        // borderBottom="1px"
        // borderColor="#31354F"
      >
        Dashboard
      </Text>
      {/* <Text
        color="text-secondary"
        textStyle="number_base"
      >
        Supply collateral to Borrow YUSD.
      </Text> */}
      <Flex
        direction="column"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        {disconnected ? (
          <ConnectCard title="Create Trove" />
        ) : borrowMode === "close" ? (
          <CloseTroveAutosell setBorrowMode={setBorrowMode} />
        ) : (
          // ? (<CloseTroveAutosell borrowMode={borrowMode} setBorrowMode={setBorrowMode} />)
          <>
            <Box
              display="flex"
              alignItems="flex-end"
              justifyContent="space-between"
              w="full"
              mb="24px"
              // borderBottom="1px"
              pb="8px"
              // borderColor="gray.700"
            >
              {/* <Box>
            <Text textStyle="subheading_display" color="text-primary">Supply Balance</Text>
            <Text textStyle="number_lg">
            {`$${getNum(currUSDValue * +String(1), 2)}`}
            </Text>
            </Box> */}

              <Box display="flex" flexDir="column" w="full">
                {/* <Text textStyle="display_md" color="#EAEDF0">
                  Dashboard
                </Text> */}
                {/* <Box display="flex" pt="8px">
                  <Link
                    mr="16px"
                    borderBottomWidth={active === "overview" ? "2px" : "none"}
                    pb="4px"
                  >
                    <Text
                      textStyle="text_md"
                      color={
                        active === "overview"
                          ? "#EAEDF0"
                          : "text-secondary"
                      }
                      _hover={{ color: "#EAEDF0" }}
                      onClick={() => setActive("overview")}
                    >
                      Overview
                    </Text>
                  </Link>
                  <Link
                    borderBottomWidth={active === "history" ? "2px" : "none"}
                    pb="4px"
                  >
                    <Text
                      textStyle="text_md"
                      color={
                        active === "history" ? "#EAEDF0" : "text-secondary"
                      }
                      _hover={{ color: "#EAEDF0" }}
                      onClick={() => setActive("history")}
                    >
                      History
                    </Text>
                  </Link>
                </Box> */}
              </Box>
            </Box>

            <AdjustTrove
              disconnected={disconnected}
              borrowMode={borrowMode}
              setBorrowMode={setBorrowMode}
            />

            {/* {active === "overview" ? (
              <AdjustTrove
                disconnected={disconnected}
                borrowMode={borrowMode}
                setBorrowMode={setBorrowMode}
              />
            ) : active === "history" ? (
              <TroveHistory />
            ) : (
              <Text>h</Text>
            )} */}
          </>
          // {/* <Trove disconnected={disconnected} borrowMode={borrowMode} setBorrowMode={setBorrowMode} /> */}
        )}
      </Flex>
    </>
  );
};

export default Borrow;
