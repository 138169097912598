import React, { useEffect, useState } from "react";

import { useLiquity } from "../../../Hooks/LiquityContext";
import { useTransactionFunction } from "../../../Components/Transaction";
import { capitalizeFirstLetter } from "../../../Utils/string";
import { Decimal, LiquityStoreState } from "@yeti/lib-base";
import { useLiquitySelector } from "@yeti/lib-react";
import { format } from "../../../Utils/number";

import CoinAmount from "../../../Components/CoinAmount";
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalCloseButton,
} from "@chakra-ui/modal";
import {
  Flex,
  Text,
  Button,
  Spacer,
  HStack,
  useDisclosure,
} from "@chakra-ui/react";
import { tokenDataMappingT } from "../../../TokenData";
import ProgressBar from "../../../Components/ProgressBar";

export type ConfirmVaultModalProps = {
  isOpen: boolean;
  onClose: () => void;
  mode: "deposit" | "withdraw";
  token: string;
  amount: string;
  total: number;
  userBalance: Decimal;
  userDeposit: Decimal;
};

const selector = ({ yusdBalance, stabilityDeposit }: LiquityStoreState) => ({
  yusdBalance,
  stabilityDeposit,
});

const ConfirmVaultModal: React.FC<ConfirmVaultModalProps> = ({
  isOpen,
  onClose,
  token,
  mode,
  amount,
  total,
  userBalance,
  userDeposit,
}) => {
  const { yusdBalance, stabilityDeposit } = useLiquitySelector(selector);
  const {
    isOpen: isTxModalOpen,
    onOpen: onTxModalOpen,
    onClose: onTxModalClosed,
  } = useDisclosure();

  const { liquity, account } = useLiquity();

  let newAmount;

  const getFormattedValue = (value: string): number => {
    if (/^[0-9.]*$/.test(value)) {
      return +value;
    }
    return 0;
  };
  const formatedAmount = getFormattedValue(amount);
  if (mode === "deposit" && formatedAmount === format(userBalance)) {
    newAmount = userBalance;
  } else {
    if (formatedAmount === format(userDeposit)) {
      newAmount = userDeposit;
    } else {
      newAmount = formatedAmount;
    }
  }
  const transactionId = token + "Vault" + mode;
  const [sendTransaction] = useTransactionFunction(
    transactionId,
    mode === "deposit"
      ? liquity.send.vaultDeposit.bind(
          liquity.send,
          tokenDataMappingT[token].address,
          newAmount
        )
      : liquity.send.vaultWithdraw.bind(
          liquity.send,
          tokenDataMappingT[token].address,
          newAmount
        )
  );

  const onDeposit = () => {
    sendTransaction();

    onClose();
    onTxModalOpen();
  };

  const [approveTransaction] = useTransactionFunction(
    "approve",
    liquity.send.approveToken.bind(
      liquity.send,
      tokenDataMappingT[token].address,
      account,
      Decimal.from("10000000000000000000")
    )
  );

  const onApprove = () => {
    approveTransaction();
  };

  // useEffect(() => {
  //   console.log("amountttt", amount)
  //   newAmount = amount
  // }, [amount]);

  const action = capitalizeFirstLetter(mode);
  const [step, setStep] = useState<0 | 1 | 2>(0);

  const checkAllowance = async (
    token: string,
    amount: Decimal
  ): Promise<boolean> => {
    const result = await liquity.getAllowanceOf(account, token, token, amount);

    return result;
  };

  useEffect(() => {
    // let tot:Decimal = Decimal.ZERO
    // if (!(getFormattedValue(amount) == 0)) {
    //   tot = Decimal.from(amount)
    // }
    const open = isOpen;
    let interval: any = undefined;
    if (open) {
      interval = setInterval(async () => {
        const allowance = await checkAllowance(
          tokenDataMappingT[token].address,
          Decimal.from(getFormattedValue(amount))
        );
        if (allowance) {
          setStep(2);
        } else {
          setStep(1);
        }
      }, 1500);
    }

    return () => clearInterval(interval);
  }, [amount, isOpen]);
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textAlign="center" pb={1}>
          Confirm {action}
          <ModalCloseButton />
        </ModalHeader>

        <ModalBody>
          <Flex>
            <Text fontSize="lg">{action} Amount:</Text>
            <Spacer />
            <CoinAmount
              amount={formatedAmount}
              token={token}
              fontWeight="bold"
              color="white"
              fontSize="md"
            />
          </Flex>
          <Flex mt={3}>
            <Text color="brand.200">Total Deposit</Text>
            <Spacer />
            <CoinAmount amount={total} token={token} fontWeight="bold" />
          </Flex>
        </ModalBody>

        <ModalFooter flexDirection="column">
          <HStack spacing={6}>
            <Button
              disabled={step === 2 && true}
              variant={step !== 1 ? "quaternary" : "newPrimary"}
              onClick={onApprove}
            >
              Approve
            </Button>

            <Button
              variant={step !== 2 ? "quaternary" : "newPrimary"}
              onClick={onDeposit}
            >
              {action}
            </Button>
          </HStack>
          <ProgressBar step={step === 2 ? 1 : 0} w="30%" mt={2} />
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ConfirmVaultModal;
