import React, { useState } from "react";
import { Box, Text, useDisclosure, Checkbox } from "@chakra-ui/react";
import { Link } from "react-router-dom";
// import { ConnectCard } from "../../Components";
import { LiquityStoreState } from "@yeti/lib-base";
import { useLiquitySelector } from "@yeti/lib-react";
import { tokenDataMappingA } from "../../TokenData";

import { WalletSelector } from "../../Components/WalletConnector";
import { CoinAmount, TokenTable, Icon } from "../../Components";
import { getNum } from "../../Utils/number";
import { useProtocolDataContext } from "../../Hooks/useProtocolDataContext";

export type BorrowProps = {
  disconnected?: boolean;
};

const selector = ({
  tokenBalances,
  vaultDepositInfo,
  prices,
}: LiquityStoreState) => ({
  tokenBalances,
  vaultDepositInfo,
  prices,
});

export interface CollateralAPYs {
  [key: string]: any;
}

const Vaults: React.FC<BorrowProps> = ({ disconnected = false }) => {
  const { tokenBalances, vaultDepositInfo, prices } =
    useLiquitySelector(selector);
  const { APYs } = useProtocolDataContext();

  const vaultTokenBalances = vaultDepositInfo.vaultTokenBalances;
  const vaultDeposits = vaultDepositInfo.vaultDeposits;
  const currVaults = vaultDepositInfo.currVaults;
  const vaultTokenTotalSupply = vaultDepositInfo.vaultTokenTotalSupply;

  const {
    isOpen: isSelectorOpen,
    onOpen: onSelectorOpen,
    onClose: onSelectorClose,
  } = useDisclosure();

  const [showZeroBalance, setShowZeroBalance] = useState(true);

  const vaultsToShow = showZeroBalance
    ? Object.keys(vaultTokenBalances)
    : Object.keys(vaultTokenBalances).filter(
        (address) =>
          tokenBalances[tokenDataMappingA[address].underlying].nonZero
      );

  return (
    <>
      {isSelectorOpen && (
        <WalletSelector
          isSelectorOpen={isSelectorOpen}
          onSelectClose={onSelectorClose}
        />
      )}
      <Box flexDir="column" display="flex" w="full" gap="0px">
        <Box layerStyle="card">
          <>
            <Box layerStyle="tableHead">
              <Text>Your Vaults</Text>
            </Box>

            <Box layerStyle="tableSub">
              <Box display="flex">
                <Box display="flex">
                  <Text>Vault</Text>
                </Box>
              </Box>
              <Box display="flex">
                <Box display="flex" justifyContent="flex-end" mr="80px">
                  <Text>Total Deposits</Text>
                </Box>
                <Box display="flex" justifyContent="flex-end" mr="80px">
                  <Text>APY</Text>
                </Box>
                <Box display="flex" justifyContent="flex-end" mr="80px">
                  <Text>Your Deposit</Text>
                </Box>
                <Box display="flex" justifyContent="flex-end" mr="80px">
                  <Text>Wallet Balance</Text>
                </Box>

                {/* <Box w="200px" /> */}
              </Box>
            </Box>

            <TokenTable borrow>
              <>
                {currVaults.map((address) => (
                  <Link
                    to={"/vault/" + tokenDataMappingA[address].token}
                    key={address}
                  >
                    <Box layerStyle="tableRow" key={address}>
                      <Box display="flex" alignItems="center" h="80px">
                        <Box
                          // onMouseEnter={() => setHover(true)}
                          // onMouseLeave={() => setHover(false)}
                          alignItems="center"
                          display="flex"
                          w="220px"
                          cursor="pointer"
                          // _hover={{bg: "surface-hover" }}
                          // py="8px"
                          // borderRadius="16px"
                          overflow="hidden"
                        >
                          <Icon
                            iconName={tokenDataMappingA[address].token}
                            h="40px"
                            w="40px"
                          />
                          <Box
                            display="flex"
                            flexDir="column"
                            alignItems="flex-start"
                            ml="12px"
                          >
                            <Text textStyle="assetName">
                              {tokenDataMappingA[address].name}
                            </Text>
                            <Text textStyle="asset">
                              {tokenDataMappingA[address].token}
                            </Text>
                          </Box>
                        </Box>

                        <Box
                          w="230px"
                          display="flex"
                          justifyContent="flex-end"
                        />

                        <Box display="flex" alignItems="center">
                          <Box
                            w="80px"
                            display="flex"
                            justifyContent="flex-end"
                            mr={12}
                          >
                            <Text>
                              $
                              {getNum(
                                Number(
                                  vaultTokenTotalSupply[address]
                                    .mul(prices[address])
                                    .toString()
                                )
                              )}
                            </Text>
                          </Box>
                          <Box
                            w="80px"
                            display="flex"
                            justifyContent="flex-end"
                            mr={1}
                          >
                            <Text>
                              {(
                                APYs[tokenDataMappingA[address].token] * 100
                              ).toFixed(2)}
                              %{" "}
                            </Text>
                          </Box>

                          <Box
                            w="130px"
                            display="flex"
                            justifyContent="flex-end"
                            // pr="114px"
                            mr={10}
                          >
                            <Box
                              display="flex"
                              flexDir="column"
                              alignItems="flex-end"
                            >
                              <Text alignItems="flex-end" whiteSpace="nowrap">
                                {getNum(
                                  Number(vaultDeposits[address].toString())
                                )}
                              </Text>
                              <CoinAmount
                                token={tokenDataMappingA[address].token}
                                noGetNum={true}
                                // noCurrencyConvert={true}
                                // firstNumber={true}
                                amount={Number(
                                  vaultDeposits[address].toString()
                                )}
                                // noCurrencyConvert={true}
                                fontSize="13px"
                                color="text-secondary"
                                fontWeight="500"
                              />
                            </Box>
                          </Box>
                          <Box
                            w="130px"
                            display="flex"
                            justifyContent="flex-end"
                            // pr="114px"
                          >
                            <Box
                              display="flex"
                              flexDir="column"
                              alignItems="flex-end"
                            >
                              <Text alignItems="flex-end" whiteSpace="nowrap">
                                {getNum(
                                  Number(
                                    tokenBalances[
                                      tokenDataMappingA[address].underlying
                                    ].toStringWithDecimals(
                                      tokenDataMappingA[address]
                                        .underlyingDecimals
                                    )
                                  )
                                )}
                              </Text>
                              <CoinAmount
                                token={tokenDataMappingA[address].token}
                                noGetNum={true}
                                // noCurrencyConvert={true}
                                // firstNumber={true}
                                amount={Number(
                                  tokenBalances[
                                    tokenDataMappingA[address].underlying
                                  ].toStringWithDecimals(
                                    tokenDataMappingA[address]
                                      .underlyingDecimals
                                  )
                                )}
                                // noCurrencyConvert={true}
                                fontSize="13px"
                                color="text-secondary"
                                fontWeight="500"
                              />
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Link>
                ))}
              </>
            </TokenTable>
          </>
        </Box>
      </Box>
      <Box mb={10} />
      <Box flexDir="column" display="flex" w="full" gap="0px">
        <Box layerStyle="card">
          <>
            <Box layerStyle="tableHead">
              <Text>Available Vaults</Text>
              <Box display="flex">
                <Checkbox
                  onChange={(e) => {
                    setShowZeroBalance(e.target.checked);
                  }}
                  size="md"
                  style={{ outline: "none" }}
                  defaultChecked={true}
                />
                <Text textStyle="text_sm" color="text-secondary" ml={3}>
                  Show vaults with 0 wallet balance
                </Text>
              </Box>
            </Box>

            <Box layerStyle="tableSub">
              <Box display="flex">
                <Box display="flex">
                  <Text>Vault</Text>
                </Box>
              </Box>
              <Box display="flex">
                <Box display="flex" justifyContent="flex-end" mr="80px">
                  <Text>Total Deposits</Text>
                </Box>
                <Box display="flex" justifyContent="flex-end" mr="80px">
                  <Text>APY</Text>
                </Box>
                <Box display="flex" justifyContent="flex-end" mr="80px">
                  <Text>Your Deposit</Text>
                </Box>
                <Box display="flex" justifyContent="flex-end" mr="80px">
                  <Text>Wallet Balance</Text>
                </Box>

                {/* <Box w="200px" /> */}
              </Box>
            </Box>

            <TokenTable borrow>
              <>
                {vaultsToShow.map((address) => (
                  <Link
                    to={"/vault/" + tokenDataMappingA[address].token}
                    key={address}
                  >
                    <Box layerStyle="tableRow" key={address}>
                      <Box display="flex" alignItems="center" h="80px">
                        <Box
                          // onMouseEnter={() => setHover(true)}
                          // onMouseLeave={() => setHover(false)}
                          alignItems="center"
                          display="flex"
                          w="220px"
                          cursor="pointer"
                          // _hover={{bg: "surface-hover" }}
                          // py="8px"
                          // borderRadius="16px"
                          overflow="hidden"
                        >
                          <Icon
                            iconName={tokenDataMappingA[address].token}
                            h="40px"
                            w="40px"
                          />
                          <Box
                            display="flex"
                            flexDir="column"
                            alignItems="flex-start"
                            ml="12px"
                          >
                            <Text textStyle="assetName">
                              {tokenDataMappingA[address].name}
                            </Text>
                            <Text textStyle="asset">
                              {tokenDataMappingA[address].token}
                            </Text>
                          </Box>
                        </Box>

                        <Box
                          w="230px"
                          display="flex"
                          justifyContent="flex-end"
                        />

                        <Box display="flex" alignItems="center">
                          <Box
                            w="80px"
                            display="flex"
                            justifyContent="flex-end"
                            mr={12}
                          >
                            <Text>
                              $
                              {getNum(
                                Number(
                                  vaultTokenTotalSupply[address]
                                    .mul(prices[address])
                                    .toString()
                                )
                              )}
                            </Text>
                          </Box>
                          <Box
                            w="80px"
                            display="flex"
                            justifyContent="flex-end"
                            mr={1}
                          >
                            <Text>
                              {(
                                APYs[tokenDataMappingA[address].token] * 100
                              ).toFixed(2)}
                              %{" "}
                            </Text>
                          </Box>

                          <Box
                            w="130px"
                            display="flex"
                            justifyContent="flex-end"
                            // pr="114px"
                            mr={10}
                          >
                            <Box
                              display="flex"
                              flexDir="column"
                              alignItems="flex-end"
                            >
                              <Text alignItems="flex-end" whiteSpace="nowrap">
                                {getNum(
                                  Number(vaultDeposits[address].toString())
                                )}
                              </Text>
                              <CoinAmount
                                token={tokenDataMappingA[address].token}
                                noGetNum={true}
                                // noCurrencyConvert={true}
                                // firstNumber={true}
                                amount={Number(
                                  vaultDeposits[address].toString()
                                )}
                                // noCurrencyConvert={true}
                                fontSize="13px"
                                color="text-secondary"
                                fontWeight="500"
                              />
                            </Box>
                          </Box>
                          <Box
                            w="130px"
                            display="flex"
                            justifyContent="flex-end"
                            // pr="114px"
                          >
                            <Box
                              display="flex"
                              flexDir="column"
                              alignItems="flex-end"
                            >
                              <Text alignItems="flex-end" whiteSpace="nowrap">
                                {getNum(
                                  Number(
                                    tokenBalances[
                                      tokenDataMappingA[address].underlying
                                    ].toStringWithDecimals(
                                      tokenDataMappingA[address]
                                        .underlyingDecimals
                                    )
                                  )
                                )}
                              </Text>
                              <CoinAmount
                                token={tokenDataMappingA[address].token}
                                noGetNum={true}
                                // noCurrencyConvert={true}
                                // firstNumber={true}
                                amount={Number(
                                  tokenBalances[
                                    tokenDataMappingA[address].underlying
                                  ].toStringWithDecimals(
                                    tokenDataMappingA[address]
                                      .underlyingDecimals
                                  )
                                )}
                                // noCurrencyConvert={true}
                                fontSize="13px"
                                color="text-secondary"
                                fontWeight="500"
                              />
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Link>
                ))}
              </>
            </TokenTable>
          </>
        </Box>
      </Box>
    </>
  );
};

export default Vaults;
