import React, { useState } from "react";
import { Box, Button, Flex, Text, useDisclosure } from "@chakra-ui/react";
import { TokenTable } from "../../../Components";

import { getNum } from "../../../Utils/number";
import { useProtocolDataContext } from "../../../Hooks/useProtocolDataContext";

export type TroveListProps = Record<string, never>;

const TroveList: React.FC<TroveListProps> = () => {
  const { sortedTrovesData } = useProtocolDataContext();

  const numberOfTroves = sortedTrovesData.length;

  const {
    isOpen: isConfirmOpen,
    onOpen: onConfirmOpen,
    onClose: onConfirmClose,
  } = useDisclosure();

  const [idx, setIdx] = useState<number>(0);

  const onClickLeft = () => {
    if (idx > 0) {
      setIdx(idx - 1);
    }
  };

  const onClickRight = () => {
    if (idx < numberOfTroves / 10 - 1) {
      setIdx(idx + 1);
    }
  };

  return (
    <>
      <Box w="full" layerStyle="card" p="24px">
        {/* <Text textStyle="card_header">Sorted Troves</Text> */}
        <Box
          display="flex"
          textStyle="subheading"
          pb="16px"
          color="text-secondary"
          pt="24px"
          justifyContent="space-between"
          alignItems="center"
          w="full"
        >
          <Box display="flex">
            <Box display="flex">
              <Text>Trove Owner</Text>
            </Box>
          </Box>
          <Box display="flex">
            <Box w="210px" display="flex" justifyContent="flex-end">
              <Text></Text>
            </Box>
            <Box pr="86px" display="flex" justifyContent="flex-end">
              <Text>Outstanding Debt</Text>
            </Box>
            <Box pr="96px" display="flex" justifyContent="flex-end">
              <Text>AICR</Text>
            </Box>
            <Box display="flex" justifyContent="flex-end">
              <Text>ICR</Text>
            </Box>
          </Box>
          {/* <Hide below="md">
            <Box w="300px">
              <Text>Wallet</Text>
            </Box>
          </Hide> */}
        </Box>
        {/* <Flex flex={2}> */}
        {/* </Flex> */}
        {
          <TokenTable
          // headers={["Trove Owner", "", "Outstanding Debt", "AICR", "ICR"]}
          // tooltips={[
          //   "",
          //   "",
          //   "Trove's debt - 200",
          //   "A trove's Adjusted Individual Collateral Ratio or AICR is a ratio between collateral and debt giving additional weight to stablecoins.",
          //   "",
          // ]}
          // width={5}
          // display={["none", "block"]}
          >
            {sortedTrovesData
              .slice(idx * 10, idx * 10 + 10)
              .map((currTroveData) => (
                <Box
                  key={currTroveData.owner}
                  display="flex"
                  textStyle="number_base"
                  justifyContent="space-between"
                  h="52px"
                  alignItems="center"
                  borderTop="1px"
                  borderColor="border"
                >
                  <Box display="flex">{currTroveData.owner}</Box>
                  <Box display="flex">
                    {[...new Array(1)].map((_) => (
                      <Box key={_} py={3} borderColor="border" fontSize={14} />
                    ))}
                    <Box pr="80px" display="flex">
                      {getNum(currTroveData.outstandingDebt, 3)}
                    </Box>
                    <Box pr="80px" display="flex">
                      {getNum(currTroveData.aicr, 3)}
                    </Box>

                    <Box display="flex">{getNum(currTroveData.icr, 3)}</Box>
                  </Box>
                </Box>
              ))}
          </TokenTable>
        }
        <Flex justifyContent="space-between">
          <Flex flex={2}>
            <Text textStyle="subtitle3" mt={6}>
              {" "}
              {(idx + 1) * 10 - 9}-
              {sortedTrovesData.length < idx * 10 + 10
                ? sortedTrovesData.length
                : idx * 10 + 10}{" "}
              of {sortedTrovesData.length} results
            </Text>
          </Flex>
          <Flex py="px" flex={2} justifyContent="right" mt={3}>
            <Button mr={3} variant="text" onClick={onClickLeft}>
              {" "}
              <Text fontSize={14}>Previous</Text>{" "}
            </Button>
            <Button
              variant="text"
              onClick={onClickRight}
              disabled={sortedTrovesData.length <= idx * 10}
            >
              {" "}
              <Text fontSize={14}>Next</Text>{" "}
            </Button>
          </Flex>
        </Flex>
      </Box>
    </>
  );
};

export default TroveList;
