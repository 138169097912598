import { Box, Text } from "@chakra-ui/react";
import React from "react";
import { SystemSummary } from "../../PageComponents/Dashboard";

export type StatsProps = {
  disconnected?: boolean;
};

const Stats: React.FC<StatsProps> = ({ disconnected = false }) => {
  return (
    <>
      <Box
        w="full"
        h="320px"
        bg="#141724"
        position="absolute"
        zIndex="-1"
        left="0"
        right="0"
        top="0"
      />
      <Text
        color="white"
        textStyle="display_md"
        borderBottom="1px"
        borderColor="#31354F"
        pb="8px"
      >
        Protocol Overview
      </Text>
      <Box mt="48px">
        <SystemSummary />
      </Box>
    </>
  );
};

export default Stats;
