import React, { useState } from "react";
import {
  Box,
  Button,
  Flex,
  Text,
  useDisclosure,
  useToast,
  IconButton,
} from "@chakra-ui/react";
import { AdjustInput, TokenTable, Icon } from "../../../Components";
import ConfirmStakeModal from "../ConfirmStakeModal";
import BoosterCalculatorModal from "../BoosterCalculatorModal";
import { LiquityStoreState, TroveMappings } from "@yeti/lib-base";
import { useLiquitySelector } from "@yeti/lib-react";
import { adjustValue, getNum, format } from "../../../Utils/number";
import { validateDeposit } from "../../../Utils/validation";
import { Form } from "react-final-form";
import { FarmPoolRewardsInfo, calculateFarmPoolRewards } from "../FarmUtils";
import PoolRewardsModal from "../../Pool/PoolRewardsModal";
import { useWeb3Context } from "../../../Hooks/useWeb3Context";
import { ConnectButton } from "../../../Components/ConnectButton";

export type BoostFarmCardProps = {
  disconnected?: boolean;
};

const selector = ({
  boostedFarm,
  lpTokenBalance,
  YETIPrice,
  veYETIStaked,
}: LiquityStoreState) => ({
  boostedFarm,
  lpTokenBalance,
  YETIPrice,
  veYETIStaked,
});

const dataSelector = useLiquitySelector;

const BoostFarmCard: React.FC<BoostFarmCardProps> = ({
  disconnected = false,
}) => {
  const { connected } = useWeb3Context();
  let lpStaked: number, totalLPStaked: number, rewardRate: number;
  const { boostedFarm, veYETIStaked } = dataSelector(selector);
  const { lpTokenBalance, YETIPrice } = dataSelector(selector);
  const [value, setValue] = useState<Record<string, any>>({});

  const {
    isOpen: isConfirmOpen,
    onOpen: onConfirmOpen,
    onClose: onConfirmClose,
  } = useDisclosure();
  const {
    isOpen: isCalculatorOpen,
    onOpen: onCalculatorOpen,
    onClose: onCalculatorClose,
  } = useDisclosure();
  const toast = useToast();

  const [mode, setMode] = useState<"deposit" | "withdraw">("deposit");

  let farmPoolRewardInfo: FarmPoolRewardsInfo = {
    userBaseRewardShare: 0,
    baseAPR: 0,
    userAnnualBaseReward: 0,
    userBoostedRewardShare: 0,
    boostedAPR: 0,
    userAnnualBoostedReward: 0,
  };

  if (!disconnected) {
    const yetiPrice = format(YETIPrice);
    lpStaked = format(boostedFarm.lpTokenBalance);
    totalLPStaked = format(boostedFarm.totalLPStaked);
    rewardRate = format(boostedFarm.rewardRate);
    const adjustAmount =
      value["stakeInput"] !== undefined && mode === "withdraw"
        ? -Number(value["stakeInput"])
        : value["stakeInput"] !== undefined && mode === "deposit"
        ? +value["stakeInput"]
        : undefined;
    farmPoolRewardInfo = calculateFarmPoolRewards(
      veYETIStaked,
      yetiPrice,
      boostedFarm,
      adjustAmount
    );
  } else {
    totalLPStaked = 0;
    lpStaked = 0;
  }
  const earned: TroveMappings = {
    "0x77777777777d4554c39223C354A05825b2E8Faa3": boostedFarm.earnedYETI,
  };

  const {
    isOpen: isPoolRewardsOpen,
    onOpen: onPoolRewardsOpen,
    onClose: onPoolRewardsClose,
  } = useDisclosure();

  const validate = (valueChange: number) => {
    validateDeposit(
      toast,
      mode,
      format(lpTokenBalance),
      lpStaked,
      valueChange,
      onConfirmOpen
    );
  };
  const smallPercent = "< 0.01";
  const stakeShare: number =
    format(boostedFarm.lpTokenBalance.div(boostedFarm.totalLPStaked)) * 100;
  const weightShare: number = farmPoolRewardInfo.userBoostedRewardShare * 100;
  const [show, setShow] = React.useState(true);
  const [showInput, setShowInput] = useState<boolean>(false);
  const handleToggle = () => setShow(!show);
  return (
    <>
      {!disconnected && (
        <BoosterCalculatorModal
          isOpen={isCalculatorOpen}
          onClose={onCalculatorClose}
        />
      )}
      <Form
        onSubmit={() => undefined}
        // format={getFormattedValue}
        render={({ values, form }) => (
          <>
            {setValue(values)}
            {!disconnected && (
              <PoolRewardsModal
                isOpen={isPoolRewardsOpen}
                onClose={onPoolRewardsClose}
                rewards={earned}
                notStability={true}
                mode="LP"
                key="prm"
              />
            )}
            {!disconnected && (
              <ConfirmStakeModal
                isOpen={isConfirmOpen}
                onClose={onConfirmClose}
                mode={mode}
                amount={values.stakeInput || "0"}
                total={adjustValue(mode, lpStaked, values.stakeInput)}
                values={values}
                name="stakeInput"
              />
            )}
            <Box layerStyle="card" w="340px" p="24px">
              <TokenTable
              // headers={[
              //   "Asset",
              //   "Total Staked",
              //   "Rewards APR",
              //   "Your Total Stake",
              //   "Your Pool Share",
              //   "Est. Weekly YETI Rewards",
              //   "YETI Earned",
              //   "Actions",
              // ]}
              // width={8}
              // display={["none", "block"]}
              >
                <>
                  <Box key="oldCurve" display="flex" flexDir="column">
                    <Box
                      display="flex"
                      flexDir="column"
                      borderBottom="1px"
                      borderColor="border"
                      pb="24px"
                    >
                      <Box display="flex">
                        <Icon iconName={"CLP"} h="32px" w="32px" />
                        <Text pl="8px" textStyle="card_header">
                          Curve LP
                        </Text>
                      </Box>
                      <Text textStyle="asset" color="green.300" pt="8px">
                        Earn LP fees
                      </Text>
                    </Box>

                    {/* Total Deposits */}
                    <Box
                      textStyle="card_text"
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      pt="24px"
                    >
                      <Text>TVL</Text>$
                      {getNum(format(boostedFarm.totalLPStaked), 2)}
                    </Box>
                    {/* Rewards APR */}
                    <Box
                      textStyle="card_text"
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      pt="24px"
                    >
                      <Text>Rewards APR</Text>
                      <Flex direction="column">
                        <Text
                          color="#34CE85"
                          textStyle="subtitle1"
                          fontSize={14}
                        >
                          {getNum(
                            farmPoolRewardInfo.baseAPR +
                              farmPoolRewardInfo.boostedAPR,
                            3
                          )}
                          %
                          <IconButton
                            mb={1}
                            _hover={{ bg: "clear" }}
                            bg="clear"
                            aria-label="Expand Boosted Calc"
                            size={"sm"}
                            onClick={() => {
                              onCalculatorOpen();
                            }}
                            icon={<Icon iconName="calculator" />}
                          />
                        </Text>
                        {/* <Text fontSize={12} color="#9B9EAC" mb={-1}>
                          Base:{getNum(farmPoolRewardInfo.baseAPR, 3)}%
                        </Text> */}
                        <Text fontSize={12} color="#9B9EAC" mt={-2}>
                          Boosted:
                          {farmPoolRewardInfo.boostedAPR > 0 &&
                          farmPoolRewardInfo.boostedAPR < 0.001
                            ? "< 0.001"
                            : getNum(farmPoolRewardInfo.boostedAPR, 3)}
                          %
                        </Text>
                      </Flex>
                    </Box>
                    <Box
                      textStyle="card_text"
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      pt="24px"
                    >
                      <Text>Your deposits</Text>
                      <Box
                        display="flex"
                        flexDir="column"
                        alignItems="flex-end"
                      >
                        {getNum(
                          adjustValue(mode, lpStaked, values.stakeInput),
                          3
                        )}

                        <Text
                          textStyle="text_sm"
                          color="text-secondary"
                          letterSpacing="tight"
                        >
                          {stakeShare < 0.01 && stakeShare != 0
                            ? smallPercent
                            : stakeShare.toFixed(3)}
                          % of pool
                        </Text>
                      </Box>
                    </Box>
                    {/* <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="flex-end"
                      w="80px"
                    >
                      <Text textStyle="subtitle1" fontSize={14}>
                        {stakeShare < 0.01 && stakeShare != 0
                          ? smallPercent
                          : stakeShare.toFixed(3)}
                        %
                      </Text>
                      <Text fontSize={12} color="#9B9EAC">
                        {weightShare < 0.01 && weightShare != 0
                          ? smallPercent
                          : weightShare.toFixed(3)}
                        %
                      </Text>
                    </Box> */}
                    <Box
                      textStyle="card_text"
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      pt="24px"
                    >
                      <Text>Est. weekly return</Text>
                      {farmPoolRewardInfo !== undefined
                        ? getNum(
                            farmPoolRewardInfo.userAnnualBaseReward / 52.143 +
                              farmPoolRewardInfo.userAnnualBoostedReward /
                                52.143,
                            3
                          )
                        : 0.0}{" "}
                      YETI
                    </Box>
                    <Box
                      textStyle="card_text"
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      pt="24px"
                    >
                      <Text>YETI Earned</Text>
                      <Box
                        display="flex"
                        flexDir="column"
                        alignItems="flex-end"
                      >
                        {getNum(format(boostedFarm.earnedYETI), 3)}
                        <Button
                          variant="text"
                          fontSize="14px"
                          fontWeight="semibold"
                          display="flex"
                          height="28px"
                          px="0px"
                          onClick={onPoolRewardsOpen}
                        >
                          View
                        </Button>
                      </Box>
                    </Box>
                    <Box
                      mt="32px"
                      display="flex"
                      alignItems="center"
                      justifyContent="flex-end"
                      // w="220px"
                      // bg="red.100"
                      h="full"
                    >
                      <Box
                        display="flex"
                        alignItems="center"
                        h="full"
                        w="full"
                        flexDir="column"
                      >
                        {showInput && (
                          <>
                            {/* <Text
                              fontSize={14}
                              color={
                                mode === "withdraw" ? "#DC444D" : "#34CE85"
                              }
                              mr={2}
                            >
                              {mode === "withdraw" ? "Unstake" : "Stake"}
                            </Text> */}
                            <AdjustInput
                              max={
                                mode === "deposit"
                                  ? format(lpTokenBalance)
                                  : format(lpStaked)
                              }
                              name="stakeInput"
                              display="flex"
                              showToken
                              showCancel
                              // showToken={true}
                              token="CLP"
                              form={form}
                              walletBalance={
                                mode === "deposit"
                                  ? format(lpTokenBalance)
                                  : format(lpStaked)
                              }
                              noCurrencyConvert={true}
                              setFarm={setShowInput}
                            />
                            <Button
                              fontSize={14}
                              variant="newPrimary"
                              ml={4}
                              w="full"
                              mt="16px"
                              onClick={() => validate(values.stakeInput)}
                            >
                              Confirm
                            </Button>
                          </>
                        )}
                        {connected
                          ? !showInput && (
                              <Box gap="16px" display="flex" w="full">
                                <Button
                                  variant="surface"
                                  fontSize="15px"
                                  fontWeight="bold"
                                  w="full"
                                  display="flex"
                                  px="0px"
                                  onClick={() => {
                                    setMode("deposit");
                                    setShowInput(true);
                                  }}
                                >
                                  Stake
                                </Button>
                                <Button
                                  variant="surface"
                                  w="full"
                                  fontSize="15px"
                                  fontWeight="bold"
                                  display="flex"
                                  px="0px"
                                  onClick={() => {
                                    setMode("withdraw");
                                    setShowInput(true);
                                  }}
                                >
                                  Unstake
                                </Button>
                              </Box>
                            )
                          : !showInput && <ConnectButton width="full" />}
                      </Box>
                    </Box>
                  </Box>
                </>
              </TokenTable>
            </Box>
          </>
        )}
      />
    </>
  );
};

export default BoostFarmCard;
