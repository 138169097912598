import React from "react";
import { Text, Box, Img } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { useDisclosure } from "@chakra-ui/react";
// import { ChevronDownIcon } from "@chakra-ui/icons";
import { useWeb3React } from "@web3-react/core";
import makeBlockie from "ethereum-blockies-base64";

import { useLiquity } from "../../../Hooks/LiquityContext";
import { formatWalletAddress } from "./../../../Utils/string";
import UserDetailsDropdown from "../UserDetailsDropdown";
import { ArrowForwardIcon } from "@chakra-ui/icons";
// import Link from "../Link";

type UserDetailsProps = {
  onChange: any;
};

const UserDetails: React.FC<UserDetailsProps> = ({ onChange }) => {
  const { deactivate, connector } = useWeb3React<unknown>();
  const { account } = useLiquity();

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      {connector != undefined ? (
        <Box
          bg="#272937"
          px="6px"
          py="6px"
          rounded="full"
          _hover={{ bg: "#343647 " }}
        >
          <Link to="/profile">
            <Box
              display="flex"
              alignItems="center"
              gap="8px"
              _hover={{ gap: "12px" }}
              transition="ease-out"
              transitionDuration="200ms"
            >
              {/* <SkeletonCircle size='2' startColor="green.500" endColor="green.300" /> */}
              <Box display="flex" alignItems="center">
                <Img
                  src={makeBlockie(account)}
                  h="24px"
                  w="24px"
                  rounded="full"
                />
                <Text
                  fontSize="14px"
                  fontWeight="600"
                  ml="8px"
                  // bg={current ? "primary-default" : "surface"}
                  color="#EAEDF0"
                  _hover={{ color: "#EAEDF0" }}
                  transitionDuration="200ms"
                >
                  {/* <CircleIcon color="#26B571" mr={2} mb={0.5} boxSize={3} /> */}
                  {formatWalletAddress(account, 5, 4, 12)}
                </Text>
              </Box>
              <ArrowForwardIcon boxSize="16px" color="#EAEDF0" />
              {/* <ChevronDownIcon w="18px" h="18px" color="#7B8395" /> */}
            </Box>
          </Link>
        </Box>
      ) : (
        <Text>{formatWalletAddress(account, 8, 4, 15)}</Text>
      )}
      <UserDetailsDropdown isOpen={isOpen} onClose={onClose} />
    </>
  );
};

export default UserDetails;
