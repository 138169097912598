import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Flex,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { AdjustInput } from "../../../Components";
import Tooltip from "../../../Components/Tooltip";
import ConfirmRedeemModal from "../ConfirmRedeemModal";
import { Decimal, LiquityStoreState } from "@yeti/lib-base";
import { useLiquitySelector } from "@yeti/lib-react";
import { getNum, format } from "../../../Utils/number";
import { validateRedeem } from "../../../Utils/validation";
import { Form } from "react-final-form";
import { useWeb3Context } from "../../../Hooks/useWeb3Context";
import { ConnectButton } from "../../../Components/ConnectButton";

import { useLiquity } from "../../../Hooks/LiquityContext";

export type RedeemCardProps = Record<string, never>;

const selector = ({ yusdBalance }: LiquityStoreState) => ({
  yusdBalance,
});

const RedeemCard: React.FC<RedeemCardProps> = () => {
  const { liquity, account } = useLiquity();

  const { connected } = useWeb3Context();

  const {
    isOpen: isConfirmOpen,
    onOpen: onConfirmOpen,
    onClose: onConfirmClose,
  } = useDisclosure();

  const { yusdBalance } = useLiquitySelector(selector);

  const [redeemFee, setRedeemFee] = useState<number>(0);

  const [collsToReceiveUpdated, setCollsToReceiveUpdated] =
    useState<boolean>(false);

  useEffect(() => {
    const getSetRedeemFee = async () => {
      setRedeemFee(format(await liquity.getRedemptionFeeRate()));
    };

    getSetRedeemFee();
  }, []);

  const toast = useToast();

  const validate = (value: number) => {
    validateRedeem(toast, format(yusdBalance), 0, value, onConfirmOpen);
    // validateRedeem(toast, 100000000, 0, value, onConfirmOpen);
  };

  return (
    <>
      <Box display="flex">
        <Box layerStyle="card" w="340px" p="24px">
          <Form
            onSubmit={() => undefined}
            render={({ values, form }) => (
              <>
                {isConfirmOpen && (
                  <ConfirmRedeemModal
                    isOpen={isConfirmOpen}
                    onClose={onConfirmClose}
                    amount={values.yusdRedeemInput || "0"}
                    values={values}
                    redeemRate={Decimal.from(redeemFee)}
                    updated={collsToReceiveUpdated}
                    setUpdated={setCollsToReceiveUpdated}
                  />
                )}
                <Flex direction="column">
                  <Text textStyle="display_xs">
                    Redeem YUSD{" "}
                    <Tooltip ml={4}>
                      You will redeem as much YUSD as you can from the riskiest
                      troves first. This ensures YUSD will have a hard peg floor
                      of close to $1.
                    </Tooltip>
                  </Text>
                  <AdjustInput
                    mt={4}
                    mb={4}
                    max={format(yusdBalance) * (1 - redeemFee - 0.0005)}
                    name="yusdRedeemInput"
                    token="YUSD"
                    showToken
                    transactionId="redeemYUSD"
                    form={form}
                    walletBalance={format(yusdBalance)}
                  />
                  {/* <Divider mt={8} mb={4} colorScheme="gray" borderColor="#373B4E" height="0px" borderWidth="2px"/> */}
                  {/* <Text textStyle="subtitle3" align="left" mt={4}>Receive:</Text> */}
                  <Flex mb={4} direction="row" justifyContent="space-between">
                    <Text textStyle="subtitle4" color="#9B9EAC" align="left">
                      Redemption Fee:{" "}
                    </Text>
                    <Text textStyle="subtitle4" color="#9B9EAC" align="left">
                      {getNum(redeemFee * 100, 3)}%
                    </Text>
                  </Flex>
                  {connected ? (
                    <Button
                      variant="newPrimary"
                      mb={4}
                      onClick={() => validate(values.yusdRedeemInput)}
                    >
                      Redeem
                    </Button>
                  ) : (
                    <ConnectButton mb={4} width={280} />
                  )}
                </Flex>

                {/* <Flex mt={4} justify="flex-end">
                  
                  <Spacer />
                  
                </Flex> */}
              </>
            )}
          />
        </Box>
      </Box>
    </>
  );
};

export default RedeemCard;
