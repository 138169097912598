const defaultTextStyle = {
  color: "text-primary",
  // fontFamily: "Helvetica",
};

const textStyles = {
  title1: {
    ...defaultTextStyle,
    fontSize: "5xl",
    fontWeight: "bold",
    color: "text-primary",
  },
  title2: {
    ...defaultTextStyle,
    fontSize: "4xl",
    fontWeight: "bold",
    color: "text-primary",
  },
  title3: {
    ...defaultTextStyle,
    fontSize: "2xl",
    fontWeight: "bold",
    color: "text-primary",
  },
  title4: {
    ...defaultTextStyle,
    fontSize: "xl",
    fontWeight: "bold",
    color: "text-primary",
  },
  title5: {
    ...defaultTextStyle,
    fontSize: "lg",
    fontWeight: "bold",
    color: "text-primary",
  },
  subtitle1: {
    ...defaultTextStyle,
    fontSize: "lg",
    fontWeight: "bold",
    color: "text-primary",
  },
  subtitle2: {
    ...defaultTextStyle,
    fontSize: "md",
    fontWeight: "bold",
    color: "text-secondary",
  },
  subtitle3: {
    ...defaultTextStyle,
    fontSize: "sm",
    fontWeight: "bold",
    color: "text-primary",
  },
  subtitle4: {
    ...defaultTextStyle,
    fontSize: "xs",
    fontWeight: "bold",
    color: "text-primary",
  },
  body1: {
    ...defaultTextStyle,
    fontSize: "md",
    fontWeight: "normal",
    color: "text-primary",
  },
  body2: {
    ...defaultTextStyle,
    fontSize: "sm",
    fontWeight: "normal",
    color: "text-primary",
  },
  body3: {
    ...defaultTextStyle,
    fontSize: "xs",
    fontWeight: "normal",
    color: "text-primary",
  },
  label1: {
    ...defaultTextStyle,
    fontSize: "0.625rem",
    fontWeight: "normal",
    color: "text-primary",
  },
  label2: {
    ...defaultTextStyle,
    fontSize: "0.5rem",
    fontWeight: "normal",
    color: "text-primary",
  },
  Dropdown: {
    ...defaultTextStyle,
    fontWeight: "light",
    fontSize: "lg",
    color: "text-primary",
  },
  Button: {
    ...defaultTextStyle,
    fontWeight: 700,
    fontSize: "lg",
    color: "text-primary",
  },
  walletAddress: {
    ...defaultTextStyle,
    fontSize: "md",
    color: "brand.200",
    p: 2,
    borderRadius: 8,
    borderColor: "brand.700",
    borderWidth: 2,
  },
  inherit: {
    fontFamily: "inherit",
    fontSize: "inherit",
    fontWeight: "inherit",
  },
  hover: {
    ...defaultTextStyle,
    cursor: "pointer",
  },
  subtitle2Link: {
    ...defaultTextStyle,
    fontSize: "md",
    fontWeight: "bold",
    textDecoration: "underline",
  },

  display_2xl: {
    ...defaultTextStyle,
    fontSize: "4.5rem",
    lineHeight: "5.625rem",
    letterSpacing: "tighter",
    fontWeight: "700",
  },
  display_xl: {
    fontSize: "3.75rem",
    lineHeight: "4.875rem",
    letterSpacing: "tight",
    fontWeight: "700",
  },
  display_lg: {
    fontSize: "3rem",
    lineHeight: "3.875rem",
    letterSpacing: "tight",
    fontWeight: "700",
  },
  display_md: {
    fontSize: "2.25rem",
    lineHeight: "2.939rem",
    letterSpacing: "tight",
    fontWeight: "900",
  },
  display_sm: {
    fontSize: "1.875rem",
    lineHeight: "2.438rem",
    letterSpacing: "normal",
    fontWeight: "700",
  },
  display_xs: {
    fontSize: "1.25rem",
    lineHeight: "1.939rem",
    letterSpacing: "normal",
    fontWeight: "700",
  },
  text_xl: {
    fontSize: "1.25rem",
    lineHeight: "1.625rem",
    letterSpacing: "normal",
    fontWeight: "500",
  },
  text_lg: {
    fontSize: "1.125rem",
    lineHeight: "1.438rem",
    letterSpacing: "normal",
    fontWeight: "500",
  },
  text_md: {
    fontSize: "16px",
    letterSpacing: "normal",
    fontWeight: "700",
  },
  text_sm: {
    fontSize: "14px",
    fontWeight: "400",
  },
  text_xs: {
    fontSize: ".75rem",
    lineHeight: "1rem",
    letterSpacing: "normal",
    fontWeight: "500",
  },
  button_md: {
    fontSize: ".875rem",
    lineHeight: "1.125rem",
    letterSpacing: "normal",
    fontWeight: "700",
  },

  subheading: {
    fontSize: "13px",
    letterSpacing: "normal",
    fontWeight: "500",
  },
  subheading_display: {
    fontSize: "16px",
    letterSpacing: "normal",
    fontWeight: "600",
  },
  card_header: {
    fontSize: "18px",
    letterSpacing: "tight",
    fontWeight: "600",
  },
  card_text: {
    fontSize: "15px",
    fontWeight: "500",
  },
  number_lg: {
    fontSize: "32px",
    letterSpacing: "tight",
    fontWeight: "800",
  },
  number_md: {
    fontSize: "22px",
    letterSpacing: "tight",
    fontWeight: "700",
  },
  number_base: {
    fontSize: "14px",
    fontWeight: "500",
  },
  number_bold: {
    fontSize: "14px",
    fontWeight: "600",
  },
  assetName: {
    fontSize: "15px",
    color: "text-primary",
    fontWeight: "500",
  },
  asset: {
    fontSize: "14px",
    color: "text-secondary",
    fontWeight: "500",
  },
};

export default textStyles;
