import React from "react";

import { Alert, Box, Link, Text } from "@chakra-ui/react";

const Announcement: React.FC = () => {
  return (
    <Alert bg="#141724" status="error" borderBottom="1px" borderColor="#33374D">
      <Box display="flex">
        <Text color="gray.200" fontSize="13.5px" letterSpacing="tight">
          A new fee model will be going in effect at the very earliest:
          September, 26th, 2022. These changes include a reduction of one-time
          borrowing fees and and introduction of a 0.5% starting interest rate
          for new and existing borrowers.
        </Text>
        <Text color="gray.200" fontSize="13.5px" ml="4px">
          Read more
        </Text>
        <Text color="#8ABEFB" fontSize="13.5px">
          <Link
            pl="4px"
            color="#8ABEFB"
            href="https://medium.com/@yetifinance/the-path-to-becoming-the-best-defi-borrowing-protocol-790459ddf93d"
            target="_blank"
            rel="noreferrer"
          >
            {"here."}
          </Link>
        </Text>
      </Box>
    </Alert>
  );
};

export default Announcement;
