import React, { useEffect, useState, useContext } from "react";
import { TroveData } from "../Types";
import tokenData, { tokenDataMappingA } from "../TokenData";

export interface CollateralAPYs {
  [key: string]: any;
}

export interface ProtocolContextData {
  sortedTrovesData: TroveData[];
  APYs: CollateralAPYs;
}

const ProtocolDataContext = React.createContext({} as ProtocolContextData);

export const ProtocolDataProvider: React.FC = ({ children }) => {
  const allTokens = tokenData.map((item) => item.address);

  const vaultTokens = allTokens.filter(
    (address) => tokenDataMappingA[address].isVault
  );

  const [sortedTrovesData, setSortedTrovesData] = useState<TroveData[]>([]);

  useEffect(() => {
    const url = "https://api.yeti.finance/v1/SortedTroves/SortedTrovesData";
    const fetchData = async () => {
      try {
        const response = await fetch(url, {
          method: "GET",
          mode: "cors",
        });
        const data = await response.json();

        setSortedTrovesData(data);
      } catch (error) {
        console.log("error", error);
      }
    };
    fetchData();
  }, []);

  const [APYs, setAPYs] = useState<CollateralAPYs>({} as CollateralAPYs);
  useEffect(() => {
    const fetchData = async () => {
      const tempAPYs: CollateralAPYs = {};

      for (let i = 0; i < vaultTokens.length; i++) {
        const token = tokenDataMappingA[vaultTokens[i]].token;

        let url = `https://api.yeti.finance/v1/Collaterals/${token}/APY`;

        if (token === "WETH-WAVAX JLP") {
          url = "https://api.yeti.finance/v1/Collaterals/WETHWAVAXJLP/APY";
        } else if (token === "AVAX-USDC JLP") {
          url = "https://api.yeti.finance/v1/Collaterals/AVAXUSDCJLP/APY";
        }
        try {
          fetch(url, { method: "GET", mode: "cors" })
            .then(function (response) {
              if (response.ok) {
                return response.json();
              }
              const err = new Error("No live API for " + token);
              throw err;
            })
            .then(function (result) {
              if (result !== undefined) {
                tempAPYs[token] = result;
              }
            })
            .catch((e) => {
              console.log(e);
            });
        } catch (error) {
          console.log("error", error);
        }
      }
      setAPYs(tempAPYs);
    };
    fetchData();
  }, []);

  return (
    <ProtocolDataContext.Provider
      value={{
        sortedTrovesData,
        APYs,
      }}
    >
      {children}
    </ProtocolDataContext.Provider>
  );
};

export const useProtocolDataContext = () => useContext(ProtocolDataContext);
