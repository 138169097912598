import React, { useEffect, useRef, useState } from "react";
import {
  //   Center,
  Flex,
  Text,
  Box,
  useDisclosure,
  useRadio,
  useRadioGroup,
} from "@chakra-ui/react";
import zoomPlugin from "chartjs-plugin-zoom";
import "chartjs-adapter-moment";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
  Filler,
  TimeScale,
  LineController,
} from "chart.js";
import { format, getNum } from "../../../../Utils/number";
import { Chart } from "react-chartjs-2";
import { Loader } from "../../../../Components";
import { useLiquity } from "../../../../Hooks/LiquityContext";
import { ApolloClient, InMemoryCache, gql } from "@apollo/client/core";

// export type TokenGraphProps = {
//   token: string;
// }

type CollateralChartProps = {
  usdValue: number;
};

const CollateralChart: React.FC<CollateralChartProps> = ({
  usdValue,
  ...props
}) => {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    LineController,
    Tooltip,
    Legend,
    TimeScale,
    zoomPlugin,
    Filler
  );

  const {
    isOpen: isFeeGraphOpen,
    onOpen: onFeeGraphOpen,
    onClose: onFeeGraphClose,
  } = useDisclosure();

  const {
    isOpen: isAPYGraphOpen,
    onOpen: onAPYGraphOpen,
    onClose: onAPYGraphClose,
  } = useDisclosure();

  const onFeeGraphClick = async () => {
    onFeeGraphOpen();
  };

  const onAPYGraphClick = async () => {
    onAPYGraphOpen();
  };

  const [portfolio, setPortfolio] = useState<any>({});

  const [loading, setLoading] = useState<boolean>(true);

  const [dataRange, setDataRange] = useState<string>("1W");

  const [balance, setBalance] = useState<number>(0);

  const { account } = useLiquity();

  const getData = async (dataType: string) => {
    const totalValues: any[] = [];
    const dates = [];
    const valueQuery = `
          query {
            troveStatuses(where: {borrower: "${account}"}) {
              totalValues
              timestamps
            }
          }
          `;
    const client = new ApolloClient({
      uri: "https://api.thegraph.com/subgraphs/name/0xcano/yeti_troves",
      cache: new InMemoryCache(),
    });

    // console.log("query", valueQuery);

    const result = await client
      .query({
        query: gql(valueQuery),
      })
      .then((data: any) => data)
      .catch((err: any) => {
        console.log("Error fetching history data: ", err);
        return [];
      });

    if (result.length == 0) {
      console.log("no result");
      return [];
    }

    const data = result.data.troveStatuses;

    if (data.length == 0) {
      const emptyData = {
        labels: [],
      };
      setPortfolio(emptyData);
      setLoading(false);
      return;
    }

    const timestamps = data[0]["timestamps"];
    const values = data[0]["totalValues"];
    // setBalance(format(Number(values[values.length - 1])));

    let length = 0;
    const now = new Date().getTime() / 1000;
    let startTime = 0;
    switch (dataRange) {
      case "1D":
        length = 60 * 60;
        startTime = now - 60 * 60 * 24;
        break;
      case "1W":
        length = 60 * 60 * 3;
        startTime = now - 60 * 60 * 24 * 7;
        break;
      case "1M":
        length = 60 * 60 * 24;
        startTime = now - 60 * 60 * 24 * 30;
        break;
      case "1Y":
        length = 60 * 60 * 24 * 7;
        startTime = now - 60 * 60 * 24 * 365;
    }

    // console.log("data", timestamps, values);

    let prev = 0;
    for (let i = 0; i < timestamps.length; i++) {
      const cur = timestamps[i];
      if (cur > startTime && cur - prev > length) {
        dates.push(new Date(cur * 1000));
        totalValues.push(values[i]);
        prev = cur;
      }
    }
    dates.push(new Date(timestamps[timestamps.length - 1] * 1000));
    totalValues.push(values[values.length - 1]);

    const apyData = {
      labels: dates,
      datasets: [
        // {
        //     label: 'min',
        //     data: minValues,
        //     color: 'red'
        // },
        // {
        //     label: 'max',
        //     data: maxValues,
        //     color: 'green'
        // },
        {
          data: totalValues,
          fill: "origin",
          pointRadius: 0,
          borderWidth: 3,
          borderColor: "#5F81F9",
          // backgroundColor: "rgba(95,129,249,0.05)",
          backgroundColor: "rgba(95,129,249,0.0)",
          tension: 0.2,
        },
        // {
        //   label: 'ICR',
        //   data: icrs,
        // }
      ],
    };
    // const port = {
    //   ...apyData,
    //   datasets: apyData.datasets.map((dataset: any) => ({
    //     ...dataset,
    //   })),
    // };

    setPortfolio(apyData);

    setLoading(false);
  };

  // console.log('modal data', data)

  useEffect(() => {
    getData(dataRange);
  }, [dataRange]);

  const options: any = {
    grid: {
      show: false,
    },
    responsive: true,
    scaleShowVerticalLines: false,
    showVerticalLines: false,
    animation: {
      duration: 0,
    },
    hover: {
      animationDuration: 0,
    },
    maintainAspectRatio: false,
    scales: {
      x: { display: false },
      xAxes: {
        display: false,
        ticks: {
          color: "",
          display: false,
        },
        type: "time",
        title: {
          display: false,
          color: "",
          text: "",
        },
        gridLines: {
          display: false,
        },
      },
      yAxes: {
        gridLines: {
          display: false,
          drawOnChartArea: false,
        },
        grid: {
          display: false,
          z: -1,
          color: "white",
        },
        ticks: {
          suggestedMin: 0,
          beginAtZero: true,
          color: "white",
          display: false,
        },
      },
    },
    interaction: {
      intersect: false,
      mode: "index",
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: function (tooltipItem: any, data: any) {
            return "$" + getNum(format(Number(tooltipItem["raw"])), 2);
          },
        },
      },
      legend: {
        display: false,
        labels: {
          color: "white",
        },
      },
      // zoom: {
      //   zoom: {
      //     wheel: {
      //       enabled: true,
      //     },
      //     pinch: {
      //       enabled: true,
      //     },
      //     mode: 'xy',
      //     overScaleMode: 'y'
      //   },
      //   pan: {
      //     enabled: true,
      //     mode: 'xy',
      //   }
      // }
    },
  };

  const chartRef = useRef<ChartJS>(null);

  function RadioCard(props: any) {
    const { getInputProps, getCheckboxProps } = useRadio(props);

    const input = getInputProps();
    const checkbox = getCheckboxProps();

    return (
      <Box as="label">
        <input {...input} />
        <Box
          {...checkbox}
          cursor="pointer"
          //   borderWidth="1px"
          fontSize="12.5px"
          fontWeight="700"
          letterSpacing="tight"
          // rounded="6px"
          color="text-secondary"
          _checked={{
            // bg: "surface-highlight",
            // py: "4px",
            // borderWidth: "1px",
            // borderColor: "border",
            color: "white",
            // px: "8px",
          }}
          _focus={{}}
          // _hover={{
          //   bg: "surface-hover",
          // }}
          px="6px"
        >
          {props.children}
        </Box>
      </Box>
    );
  }

  const graphOptions = ["1D", "1W", "1M", "1Y"];

  const handleRadiochange = (value: any) => {
    setDataRange(value);
  };

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: "framework",
    defaultValue: "1W",
    onChange: handleRadiochange,
  });

  const group = getRootProps();

  return (
    <Box
    // bg="surface"
    // borderTopRadius="8px"
    // borderBottomRadius="0px"
    // // borderWidth="1px"
    // borderColor="border"
    // p="24px"
    // pb="0px"
    // display="flex"
    // flexDir="column"
    // shadow="shadow_sm"
    // layerStyle="card"
    >
      <Box
        display="flex"
        w="full"
        justifyContent="space-between"
        alignItems="flex-start"
        // p="24px"
        // pt="24px"
      >
        <Box>
          <Text color="text-secondary" textStyle="subheading_display">
            Deposit Balance
          </Text>
          <Text color="white" textStyle="number_md">
            {" "}
            ${getNum(usdValue, 2)}{" "}
          </Text>
        </Box>

        {/* <Box>
        <Text textStyle="subheading_display" color="text-primary">
          Supply Balance
        </Text>
        <Text textStyle="number_lg">
          {`$${getNum(currUSDValue * +String(1), 2)}`}
        </Text>
      </Box> */}

        <Box
          display="flex"
          alignItems="center"
          {...group}
          // bg="surface"
          // border="1px"
          // borderColor="surface-highlight"
          // py="2px"
          // rounded="8px"
          // px="2px"
          gap="2px"
        >
          {graphOptions.map((value) => {
            const radio = getRadioProps({ value });
            return (
              <RadioCard key={value} {...radio}>
                {value}
              </RadioCard>
            );
          })}
        </Box>
      </Box>
      {loading ? (
        <Flex flexDirection="column" alignItems="center">
          <Loader />
          <Text color="text-primary" textStyle="title4">
            {" "}
            Loading
          </Text>
        </Flex>
      ) : portfolio.labels.length == 0 ? (
        <>
          <Box
            pt="16px"
            h="180px"
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <Text mt={5} mb={5} textStyle="white" color="white">
              Nothing deposited yet
            </Text>
          </Box>
        </>
      ) : (
        <Box pt="16px" h="180px" w="full">
          <Chart
            ref={chartRef}
            type="line"
            data={portfolio}
            color="text-primary"
            options={options}
          />
        </Box>
      )}

      {/* </Center> */}
      {/* </Box> */}
      {/* <HStack mb={3} {...group}>
            {graphOptions.map((value) => {
            const radio = getRadioProps({ value });
            return (
                <RadioCard key={value} {...radio}>
                {value}
                </RadioCard>
            );
            })}
    </HStack> */}
    </Box>
  );
};

export default CollateralChart;
