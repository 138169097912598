import { Box, Text } from "@chakra-ui/react";
import { RedeemCard, TroveList } from "../../PageComponents/Redemption";
import React from "react";

export type RedemptionProps = {
  disconnected?: boolean;
};

const Redemption: React.FC<RedemptionProps> = ({ disconnected = false }) => {
  return (
    <>
      <Box
        w="full"
        h="328px"
        bg="#141724"
        position="absolute"
        zIndex="-1"
        left="0"
        right="0"
        top="0"
      />
      <Box display="flex" flexDir="column" w="full ">
        <Box borderBottom="1px" borderColor="#31354F" pb="8px">
          <Text color="white" textStyle="display_md">
            Redeem YUSD
          </Text>
          <Text
            textStyle="text_md"
            color="text-secondary"
            textAlign={["center", "left"]}
            fontWeight="normal"
          >
            YUSD can be swapped for equal value in underlying collateral at any
            time.
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={
                "https://techdocs.yeti.finance/how-does-yeti-finance-work/redemptions-and-yusd-price-stability#what-are-redemptions"
              }
              style={{ outline: "none", color: "#6079FF" }}
            >
              {" "}
              Learn More
            </a>
          </Text>
        </Box>
        <Box display="flex" gap="24px" mt="48px">
          <Box display="flex" w="full">
            <TroveList />
          </Box>
          <RedeemCard />
        </Box>
      </Box>
    </>
  );
};

export default Redemption;
